<script>

export default {
    components: {
    },
    data(){
        return {
            
        }
    }
}
</script>
<template>
    <div class="d-flex align-items-center justify-content-center text-center pt-5 my-5">
        <div class="container-xxl container-p-y pt-5">
            <div class="misc-wrapper">
                <h1 class="mb-2"><strong>You are not authorized!</strong>(</h1>
                <p class="mb-4">You don’t have permission to access this page.</p>
                <a href="/" class="btn btn-primary">Back to Dashboard</a>
                <div class="mt-3">
                <img src="@/assets/images/not-auth.png" alt="page-misc-error-light" width="500">
                </div>
            </div>
        </div>
    </div>
</template>